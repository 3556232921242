import React from "react";
import PropTypes from "prop-types";
import { Image } from "./image";

export const Bildergalerie = ({ data }) => {
  return (
      <div id="bildergalerie">
        <div className="container">
          <div className="col-md-offset-1 section-title">
            <h2>Bildergalerie</h2>
            <p></p>
          </div>
          <div className="col-md-10 col-md-offset-1">
            <div className="row">
              <div className="bildergalerie-items">
                {data ? (
                    data.map((d, i) => (
                        <div key={`${d.title}-${i}`} className="col-sm-5 col-md-4 col-lg-4">
                          <Image title={d.title} Image={d.smallImage} />
                        </div>
                    ))
                ) : (
                    "Loading..."
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
  );
};

Bildergalerie.propTypes = {
  data: PropTypes.arrayOf(
      PropTypes.shape({
        title: PropTypes.string.isRequired,
        smallImage: PropTypes.string.isRequired,
      })
  ),
};

Bildergalerie.defaultProps = {
  data: [],
};
