import React from "react";
import PropTypes from "prop-types";

export const UnserVerein = ({ data }) => {
  return (
      <div id="unserVerein">
        <div className="container">
          <div className="row">
            <div className="col-xs-12 col-md-6">
              <img src="/img/Wappen.jpg" className="img-responsive" alt="Wappen" />
            </div>
            <div className="col-xs-12 col-md-6">
              <div className="section-title">
                <h2>Unser Verein</h2>
                <p>{data ? data.text : "loading..."}</p>
                <h3>Öffnungszeiten</h3>
                <div className="list-style">
                  <div className="col-lg-12 col-sm-12 col-xs-12">
                    <ul>
                      {data
                          ? data.oeffnungszeiten.map((d, i) => (
                              <li key={`${d}-${i}`}>{d}</li>
                          ))
                          : "loading"}
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
  );
};

UnserVerein.propTypes = {
  data: PropTypes.shape({
    text: PropTypes.string,
    oeffnungszeiten: PropTypes.arrayOf(PropTypes.string),
  }),
};

UnserVerein.defaultProps = {
  data: {
    text: '',
    oeffnungszeiten: []
  },
};
