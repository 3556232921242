import React, { useEffect } from "react";
import PropTypes from "prop-types";

export const Navigation = () => {
  const handleScroll = (event) => {
    event.preventDefault();
    const targetId = event.currentTarget.getAttribute("href");
    const targetElement = document.querySelector(targetId);

    const navbarHeight = document.querySelector(".navbar").offsetHeight;

    if (targetElement) {
      const targetPosition = targetElement.getBoundingClientRect().top + window.pageYOffset - navbarHeight;
      window.scrollTo({
        top: targetPosition,
        behavior: "smooth"
      });
    }
  };

  useEffect(() => {
    const links = document.querySelectorAll('.page-scroll');
    links.forEach(link => {
      link.addEventListener('click', handleScroll);
    });

    return () => {
      links.forEach(link => {
        link.removeEventListener('click', handleScroll);
      });
    };
  }, []);

  return (
      <nav id="menu" className="navbar navbar-default navbar-fixed-top">
        <div className="container">
          <div className="navbar-header">
            <button
                type="button"
                className="navbar-toggle collapsed"
                data-toggle="collapse"
                data-target="#bs-example-navbar-collapse-1"
            >
              <span className="sr-only">Toggle navigation</span>
              <span className="icon-bar"></span>
              <span className="icon-bar"></span>
              <span className="icon-bar"></span>
            </button>
            <a href="#header" className="page-scroll h1">
              Sportschützenverein Wachenheim 1963 e.V.
            </a>
          </div>

          <div className="collapse navbar-collapse" id="bs-example-navbar-collapse-1">
            <ul className="nav navbar-nav navbar-right">
              {["termine", "unserVerein", "aktuelles", "ausstattung", "ansprechpartner", "bildergalerie", "archiv", "kontakt"].map((item) => (
                  <li key={item}>
                    <a href={`#${item}`} className="page-scroll">
                      {item.charAt(0).toUpperCase() + item.slice(1)}
                    </a>
                  </li>
              ))}
            </ul>
          </div>
        </div>
      </nav>
  );
};

Navigation.propTypes = {
  data: PropTypes.arrayOf(PropTypes.string),
};

Navigation.defaultProps = {
  data: [],
};
