import React from "react";
import PropTypes from "prop-types";

export const Aktuelles = ({ data }) => {
    return (
        <div id="aktuelles">
            <div className="container">
                <div className="col-md-offset-1 section-title">
                    <h2>Aktuelles</h2>
                </div>
                <div className="col-md-10 col-md-offset-1">
                    <div className="row">
                        {data && data.listen ? (
                            data.listen.map((liste, index) => (
                                <div key={index} className="col-md-6 list-style">
                                    <h3>{liste.title}</h3>
                                    <div className="col-lg-10">
                                        <ul>
                                            {liste.items.map((item, i) => (
                                                <li key={`${item}-${i}`}>{item}</li>
                                            ))}
                                        </ul>
                                    </div>
                                </div>
                            ))
                        ) : (
                            "loading..."
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

Aktuelles.propTypes = {
    data: PropTypes.shape({
        listen: PropTypes.arrayOf(
            PropTypes.shape({
                title: PropTypes.string.isRequired,
                items: PropTypes.arrayOf(PropTypes.string).isRequired,
            })
        ),
    }),
};

Aktuelles.defaultProps = {
    data: {
        listen: [],
    },
};
