import React from "react";
import PropTypes from "prop-types";

export const Ansprechpartner = ({ data }) => {
  return (
      <div id="ansprechpartner">
        <div className="container">
          <div className="col-md-offset-1 section-title">
            <h2>Ansprechpartner</h2>
            <p>Dies sind unsere Ansprechpartner für Sie.</p>
          </div>
          <div className="col-md-10 col-md-offset-1">
            <div id="row">
              {data ? (
                  data.map((d, i) => (
                      <div key={`${d.name}-${i}`} className="col-md-4 col-sm-4 ansprechpartner">
                        <div className="thumbnail">
                          <img src={d.img} alt="..." className="ansprechpartner-img" />
                          <div className="caption">
                            <h4>{d.name}</h4>
                            <p>{d.job}</p>
                          </div>
                        </div>
                      </div>
                  ))
              ) : (
                  "loading"
              )}
            </div>
          </div>
        </div>
      </div>
  );
};

Ansprechpartner.propTypes = {
  data: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string.isRequired,
        job: PropTypes.string.isRequired,
        img: PropTypes.string.isRequired,
      })
  ),
};

Ansprechpartner.defaultProps = {
  data: [],
};
