import React from 'react';
import Popup from 'reactjs-popup';
import PropTypes from 'prop-types';

const Datenschutz = () => (
    <Popup
        trigger={<a>Datenschutz</a>}
        position="top"
        contentStyle={{
            maxWidth: '900px',
            padding: '20px',
            borderRadius: '10px',
            borderWidth: '1px',
            borderColor: '#05396b',
            backgroundColor: '#fff',
            textAlign: 'left',
            borderStyle: 'solid',
        }}
    >
        <>
            <div className="container">
                <h1>Datenschutzerklärung:</h1>
                <h3>Verantwortlicher:</h3>
                <p>
                    Sportschützenverein Wachenheim 1963 e.V.
                    <br /> Vertreten durch: Dieter Scheurer (1. Vorsitzender)
                    <br /> Im Poppenthal, DE-67157 Wachenheim
                    <br /> Tel.: 06322-65498 (nur während der Öffnungszeiten)
                    <br /> E-Mail: d.scheurer@freenet.de
                </p>
                <h3>Allgemeine Datenverarbeitung:</h3>
                <p>
                    Wir verarbeiten personenbezogene Daten nur zur Bereitstellung unserer Website sowie der Inhalte und Leistungen.
                    <br /> Rechtsgrundlage ist Art. 6 Abs. 1 lit. a, b, c und f DSGVO.
                </p>
                <h3>Datenlöschung:</h3>
                <p>
                    Daten werden gelöscht, sobald der Zweck der Speicherung entfällt, es sei denn, es bestehen gesetzliche
                    <br/>
                    Aufbewahrungspflichten.
                </p>
                <h3>Logfiles:</h3>
                <p>
                    Bei jedem Zugriff auf unsere Website werden Daten wie IP-Adresse, Browsertyp und Uhrzeit gespeichert. Diese
                    <br />Speicherung ist notwendig für den Betrieb der Website (Art. 6 Abs. 1 lit. f DSGVO).
                </p>
                <h3>Cookies:</h3>
                <p>
                    Wir verwenden Cookies zur Verbesserung der Nutzerfreundlichkeit. Sie können die Speicherung von Cookies in den
                    <br /> Einstellungen Ihres Browsers deaktivieren.
                </p>
                <h3>Kontaktformular und E-Mail:</h3>
                <p>
                    Bei Kontaktaufnahme über das Formular oder per E-Mail werden Ihre Daten zur Bearbeitung der Anfrage
                    gespeichert. Sie <br /> können die Speicherung jederzeit widersprechen, indem Sie uns per E-Mail
                    (kontakt@ssv-wachenheim.de) kontaktieren.
                </p>
            </div>
        </>
    </Popup>
);

Datenschutz.propTypes = {
    trigger: PropTypes.node,
    position: PropTypes.string,
    contentStyle: PropTypes.object,
};

export default Datenschutz;
