import React, { useState, useEffect } from "react";
import { Navigation } from "./components/navigation";
import { Header } from "./components/header";
import { Termine } from "./components/termine";
import { UnserVerein } from "./components/unserVerein";
import { Ausstattung } from "./components/ausstattung";
import { Bildergalerie } from "./components/bildergalerie";
import { Archiv } from "./components/archiv";
import { Ansprechpartner } from "./components/ansprechpartner";
import { Kontakt } from "./components/kontakt";
import { Aktuelles } from "./components/aktuelles";
import "./App.css";

const App = () => {
    const [landingPageData, setLandingPageData] = useState({});
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        fetch("/data.json")
            .then((response) => response.json())
            .then((data) => {
                setLandingPageData(data);
                setIsLoading(false);
            })
            .catch((error) => {
                console.error("Error loading data:", error);
                setError("Failed to load data");
                setIsLoading(false);
            });
    }, []);

    if (isLoading) {
        return <p>Loading...</p>;
    }

    if (error) {
        return <p>{error}</p>;
    }

    return (
        <div>
            <Navigation />
            <Header />
            <Termine data={landingPageData.Termine} />
            <UnserVerein data={landingPageData.UnserVerein} />
            <Aktuelles data={landingPageData.Aktuelles} />
            <Ausstattung data={landingPageData.Ausstattung} />
            <Ansprechpartner data={landingPageData.Ansprechpartner} />
            <Bildergalerie data={landingPageData.Bildergalerie} />
            <Archiv data={landingPageData.Archiv} />
            <Kontakt data={landingPageData.Kontakt} />
        </div>
    );
};

export default App;
