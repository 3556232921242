import React from "react";
import PropTypes from "prop-types";

export const Archiv = ({ data }) => {
    return (
        <div id="archiv">
            <div className="container">
                <div className="col-md-offset-1 section-title">
                    <h2>Archive</h2>
                </div>
                <div className="col-md-10 col-md-offset-1">
                    <div className="row">
                        {data ? (
                            data.map((d, i) => (
                                <div key={`${d.name}-${i}`} className="col-lg-6 col-md-6">
                                    <div className="service-desc">
                                        <h3>{d.name}</h3>
                                        <p>{d.text}</p>
                                    </div>
                                </div>
                            ))
                        ) : (
                            "loading"
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

Archiv.propTypes = {
    data: PropTypes.arrayOf(
        PropTypes.shape({
            name: PropTypes.string.isRequired,
            text: PropTypes.string.isRequired,
        })
    ),
};

Archiv.defaultProps = {
    data: [],
};
