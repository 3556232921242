import React from "react";
import PropTypes from "prop-types";

export const DownloadLink = ({ title, url, fileName, buttonText }) => {
    const handleDownload = (event) => {
        event.preventDefault();
        fetch(url)
            .then((response) => {
                console.log("Response headers:", response.headers);
                return response.blob();
            })
            .then((blob) => {
                console.log("Blob:", blob);
                const downloadUrl = window.URL.createObjectURL(blob);
                const link = document.createElement("a");
                link.href = downloadUrl;
                link.download = fileName || "downloaded-file";
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
                window.URL.revokeObjectURL(downloadUrl);
            })
            .catch((error) => {
                console.error("Error fetching the file:", error);
            });
    };

    return (
        <div>
            <p>{title}</p>
            <a
                href="#"
                className="btn btn-custom btn-lg page-scroll"
                onClick={handleDownload}
            >
                {buttonText}
            </a>
        </div>
    );
};

DownloadLink.propTypes = {
    title: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired,
    fileName: PropTypes.string,
    buttonText: PropTypes.string.isRequired,
};

DownloadLink.defaultProps = {
    fileName: "downloaded-file",
};
