import React from 'react';
import Popup from 'reactjs-popup';
import PropTypes from 'prop-types';

const Impressum = () => (
    <Popup
        trigger={<a>Impressum</a>}
        position="top"
        contentStyle={{
            maxWidth: '900px',
            padding: '20px',
            borderRadius: '10px',
            borderWidth: '1px',
            borderColor: '#05396b',
            backgroundColor: '#fff',
            textAlign: 'left',
            borderStyle: 'solid',
        }}
    >
        <>
            <div className="container">
                <h1>Impressum:</h1>
                <h3>Sportschützenverein Wachenheim 1963 e.V.</h3>
                <p>
                    Vertreten durch:
                    <br /> Dieter Scheurer (1. Vorsitzender)
                    <br /> Im Poppenthal
                    <br /> DE-67157 Wachenheim
                    <br /> Registergericht: Ludwigshafen Nr. 343
                </p>
                <h3>Kontakt:</h3>
                <p>Website: www.ssv-wachenheim.de</p>
                <h3>Webmaster:</h3>
                <p>
                    Dieter Scheurer
                    <br /> Udo Walter Scheurer
                    <br /> Torsten Jamin
                </p>
                <h3>Haftung für Inhalte:</h3>
                <p>
                    Die Inhalte dieser Website wurden mit größtmöglicher Sorgfalt erstellt. Für die Richtigkeit,
                    Vollständigkeit und <br/>Aktualität übernehmen wir keine Gewähr.
                </p>
                <h3>Haftung für Links:</h3>
                <p>Externe Links unterliegen der Haftung der jeweiligen Betreiber.</p>
                <h3>Urheberrecht:</h3>
                <p>
                    Die Inhalte dieser Website unterliegen dem deutschen Urheberrecht. Vervielfältigung nur mit
                    schriftlicher Genehmigung.
                </p>
            </div>
        </>
    </Popup>
);

Impressum.propTypes = {
    trigger: PropTypes.node,
    position: PropTypes.string,
    contentStyle: PropTypes.object,
};

export default Impressum;
