import React from "react";
import PropTypes from "prop-types";
import Impressum from "./impressum";
import Datenschutz from "./datenschutz";
import { DownloadLink } from "./downloadLink";

export const Kontakt = ({ data }) => {
    return (
        <div>
            <div id="kontakt">
                <div id="green-bg" className="container">
                    <div className="col-md-offset-1 section-title">
                        <h2>Kontaktieren Sie uns</h2>
                    </div>
                    <div className="col-md-5 col-md-offset-1">
                        <div className="row">
                            <div className="kontakt-item">
                                <p>
                  <span>
                    <i className="fa fa-map-marker"></i> Adresse
                  </span>
                                    {data ? (
                                        <a
                                            href={`https://www.google.com/maps?q=${encodeURIComponent(
                                                data.address
                                            )}`}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                        >
                                            {data.address}
                                        </a>
                                    ) : (
                                        "loading"
                                    )}
                                </p>
                            </div>
                            <div className="kontakt-item">
                                <p>
                  <span>
                    <i className="fa fa-phone"></i> Telefon
                  </span>
                                    <a>{data ? data.phone : "loading"}</a>
                                </p>
                            </div>
                            <div className="kontakt-item">
                                <p>
                  <span>
                    <i className="fa fa-envelope-o"></i> E-Mail Adresse
                  </span>
                                    <a>{data ? data.email : "loading"}</a>
                                </p>
                            </div>
                            <div className="kontakt-item">
                                <p>
                  <span>
                    <i className="fa fa-facebook"></i> Soziale Medien
                  </span>
                                    <a href={data ? data.facebook : "/"} target="_blank" rel="noreferrer">
                                        Facebook
                                    </a>
                                </p>
                            </div>
                        </div>
                    </div>
                    {data && data.downloadTitle && (
                        <div className="col-md-5 col-md-offset-1 mb-auto">
                            <DownloadLink
                                title={data.downloadTitle}
                                url={data.downloadUrl}
                                buttonText={data.downloadButtonText}
                                fileName={data.downloadFileName}
                            />
                        </div>
                    )}
                </div>
            </div>

            <div id="footer" className="">
                <div className="footer-content">
                    <p><Datenschutz/></p>
                    <p><Impressum/></p>
                </div>
                <div className="footer-content">
          <span>
            © Sportschützenverein Wachenheim 1963 e.V. Design by{' '}
            <a href="https://github.com/trstnjmn" target="_blank" rel="noreferrer">Torsten Jamin</a>
          </span>
                </div>
            </div>
        </div>
    );
};

Kontakt.propTypes = {
    data: PropTypes.shape({
        address: PropTypes.string,
        phone: PropTypes.string,
        email: PropTypes.string,
        facebook: PropTypes.string,
        downloadTitle: PropTypes.string,
        downloadUrl: PropTypes.string,
        downloadButtonText: PropTypes.string,
        downloadFileName: PropTypes.string,
    }),
};

Kontakt.defaultProps = {
    data: null,
};
