import React from "react";
import PropTypes from "prop-types";

export const Image = ({ title, Image }) => (
    <div className="bildergalerie-item">
        <div className="hover-bg">
            <a href={Image} title={title} data-lightbox-gallery="gallery1">
                <div className="hover-text">
                    <h4>{title}</h4>
                </div>
                <img src={Image} className="img-responsive" alt={title} />
            </a>
        </div>
    </div>
);

Image.propTypes = {
    title: PropTypes.string.isRequired,
    Image: PropTypes.string.isRequired,
};

Image.defaultProps = {
    title: "",
    Image: "",
};
