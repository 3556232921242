import React from "react";

export const Header = () => {
  return (
    <header id="header">
      <div className="intro">
          <div className="container">
            <div className="row">
              <div className="col-md-12 intro-text">
                <h1>
                  Sportschützenverein Wachenheim 1963 e.V.
                  <span></span>
                </h1>
                <p>Herzlich willkommen auf der offiziellen Website des Sportschützenvereins Wachenheim 1963 e.V.! Seit über 60 Jahren sind wir stolz darauf, eine Gemeinschaft von Schießsportbegeisterten zu sein, die sowohl Tradition als auch sportliche Exzellenz pflegen.</p>
              </div>
            </div>
          </div>
      </div>
    </header>
  );
};
